document.addEventListener('turbo:load', function() {
  window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload()
    }
  };
  $(document).ready(function () {
    var feedbackCarouselIndex = 1;
    var initialLengthFeedbackCarousel = $('.feedback-customer__slider').children().length;
    $(".feedback-customer__prev").addClass("disabled");
    var locale = $("body").attr("data-content");
    // READ MORE PROJECT
    $(".project-modal__read-more").click(function () {
      var content = $(".project-modal__content");

      if (content.css("overflow") === "hidden") {
        content.css({
          height: "auto",
          overflow: "auto",
        });
        var text = locale == 'en' ? 'Less' : 'Rút gọn'
        $(this).text(text);
      } else {
        content.css({
          height: 100,
          overflow: "hidden",
        });
        var text = locale == 'en' ? 'Read more' : 'Đọc thêm'
        $(this).text(text);
      }
    });

    // CLOSE PROJECT POPUP
    $(".project-modal .modal-close").click(function () {
      var id = $(this).attr("data-id")
      $("#project-modal-"+id).removeClass("is-active");
      resetReadMoreProjectPopup(locale);
    });
    $(document).click((e) => {
      var id = $(".project-modal.is-active .modal-close").attr("data-id")
      var isOutsideCard = $.contains($("#project-modal-"+id).get(0), e.target) && !$.contains($("#project-modal-"+id+" .modal-card").get(0), e.target);
      if (isOutsideCard && $("#project-modal-"+id).hasClass("is-active")) {
        $("#project-modal-"+id).removeClass("is-active");
        resetReadMoreProjectPopup(locale);
      }
    });

    // OPEN PROJECT POPUP
    $(".project__card").click(function () {
      var id = $(this).attr("data-id")
      $("#project-modal-"+id).addClass("is-active");
    });

    // READ MORE SERVICE PAGE
    $(".service-page__read").click(function () {
      if (
        $(this).siblings(".service-page__description").css("display") ===
        "none"
      ) {
        var tempScrollPosition = $(window).scrollTop();
        $(this).siblings(".service-page__description").css({
          display: "block",
        });
        $(this).siblings(".service-page__preview").css({
          display: "none",
        });
        var text = locale == 'en' ? 'Less' : 'Rút gọn'
        $(this).text(text);
        $(window).scrollTop(tempScrollPosition);
      } else {
        $(this).siblings(".service-page__description").css({
          display: "none",
        });
        $(this).siblings(".service-page__preview").css({
          display: "block",
        });
        $("html, body").animate({
          scrollTop: $(this).siblings(".service-page__name")[0].offsetTop
        }, 0);
        var text = locale == 'en' ? 'Read more' : 'Đọc thêm'
        $(this).text(text);
      }
    });

    // PROCEDURE CAROUSEL CHANGE STEP
    $(".procedure__nav-item").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var eleClass = $(this).attr("class");
      var selectedNum = "";
      eleClass.split(" ").forEach(function (className) {
        if (className.includes("procedure__nav-item--")) {
          selectedNum = className.replace("procedure__nav-item--", "");
        }
      });
      var item = $(".procedure__item--" + selectedNum);
      item.css("visibility", "visible");
      item.siblings().css("visibility", "hidden");
    });


    // PROCEDURE CAROUSEL ARROW DOWN CLICK
    $(".procedure__arrow-down").click(function () {
      const lastElement = $(
        ".procedure__nav-item--" + $(".procedure__nav-list").children().length
      );
      var elementTop = lastElement.offset().top;
      var elementBottom = elementTop + lastElement.outerHeight();

      var viewportTop = $(".procedure__nav").offset().top;
      var viewportBottom = viewportTop + $(".procedure__nav").outerHeight() - 55;

      if (elementBottom > viewportTop && elementTop < viewportBottom) {
        $(".procedure__arrow-down").css({
          opacity: 0.5,
          cursor: "auto",
        });
      } else {
        var currentTop = Number(
          $(".procedure__nav-list").css("top").replace("px", "")
        );
        $(".procedure__nav-list").css("top", currentTop - 85);
        $(".procedure__arrow-up").css({
          opacity: 1,
          cursor: "pointer",
        });
      }

      var currentNavItem = $(".procedure__nav-item.active");
      var eleClass = currentNavItem.attr("class");
      var selectedNum = "";
      eleClass.split(" ").forEach(function (className) {
        if (className.includes("procedure__nav-item--")) {
          selectedNum = Number(className.replace("procedure__nav-item--", "")) ;
        }
      });
      if (selectedNum < $(".procedure__nav-list").children().length) {
        currentNavItem.removeClass("active");
        var nextItemNum = selectedNum + 1;
        $(".procedure__nav-item--" + nextItemNum).addClass("active");
        var item = $(".procedure__item--" + nextItemNum);
        item.css("visibility", "visible");
        item.siblings().css("visibility", "hidden");
      }
    });

    // PROCEDURE CAROUSEL ARROW UP CLICK
    $(".procedure__arrow-up").click(function () {
      var currentTop = Number(
        $(".procedure__nav-list").css("top").replace("px", "")
      );
      if (currentTop <= -85) {
        $(".procedure__nav-list").css("top", currentTop + 85);
        $(".procedure__arrow-down").css({
          opacity: 1,
          cursor: "pointer",
        });
      } else {
        $(".procedure__nav-list").css("top", 0);
        $(".procedure__arrow-up").css({
          opacity: 0.5,
          cursor: "auto",
        });
      }

      var currentNavItem = $(".procedure__nav-item.active");
      var eleClass = currentNavItem.attr("class");
      var selectedNum = "";
      eleClass.split(" ").forEach(function (className) {
        if (className.includes("procedure__nav-item--")) {
          selectedNum = Number(className.replace("procedure__nav-item--", "")) ;
        }
      });
      if (selectedNum > 1) {
        currentNavItem.removeClass("active");
        var nextItemNum = selectedNum - 1;
        $(".procedure__nav-item--" + nextItemNum).addClass("active");
        var item = $(".procedure__item--" + nextItemNum);
        item.css("visibility", "visible");
        item.siblings().css("visibility", "hidden");
      }
    });


    // READ MORE ABOUT PAGE - FOUNDER
    $(".about-page__read-more").click(function () {

      if ($(this).siblings(".story").css("overflow") === "hidden") {
        $(this).siblings(".story").css({
          "max-height": "calc(100% - 200px)",
          overflow: "auto",
        });
        var text = locale == 'en' ? 'Less' : 'Rút gọn'
        $(this).text(text);
      } else {
        $(this).siblings(".story").css({
          "max-height": 350,
          overflow: "hidden",
        });
        var text = locale == 'en' ? 'Read more' : 'Đọc thêm'
        $(this).text(text);
      }
    });

    // OPEN/COLLAPSE RECRUIT PAGE - JOB ITEM
    $(".recruit-page__job-open").click(function () {
      if (!$(".recruit-page__job-header").attr("class").includes("open")) {
        if ($(this).closest(".recruit-page__job-item").attr("class").includes("open")) {
          $(this).closest(".recruit-page__job-item").removeClass("open");
        } else {
          $(this).closest(".recruit-page__job-item").addClass("open");
        }
      }
    });

    // MOBILE NAVBAR
    $(".navbar-burger").click(function () {
      if (!$(this).attr("class").includes("is-active")) {
        $(".navbar-brand .navbar-item").css("display", "none");
        $(this).css("top", "30px");
        $("body").css("position", "fixed");
      } else {
        $(".navbar-brand .navbar-item").css("display", "flex");
        $(this).css("top", "50%");
        $("body").css("position", "initial");
      }
      $(this).toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");
    });
    $(".navbar-company .navbar-link").click(function () {
      $(".navbar-company").toggleClass("is-active");
    });
    $(".navbar-lang .navbar-link").click(function () {
      $(".navbar-lang").toggleClass("is-active");
    });

    // MOBILE PROCEDURE COLLAPSE
    $(".procedure__carousel--mobile").css("height", $(".procedure__carousel--mobile .list").outerHeight());
    $(".procedure__carousel--mobile .item-header .procedure-icon").click(function () {
      $(".procedure__carousel--mobile .item").removeClass("open");
      $(this).parents(".item").toggleClass("open");
      $(".procedure__carousel--mobile").css("height", $(".procedure__carousel--mobile .list").outerHeight());
    });

    /* FEEDBACK CUSTOMER SLIDER
    * https://kenwheeler.github.io/slick/
    */
    $(".feedback-customer .slide-arrow").click(function () {
      const isNext = $(this).data("toggle") === "next";

      var carousel = $('.feedback-customer__slider');

      var carouselWidth = $('.feedback-customer__slide').width();

      var items = carousel.children();

      var offset;
      if (isNext) {
        offset = -(feedbackCarouselIndex * carouselWidth);
        for (var i = 0; i < items.length; i++) {
          var item = items.get(i);
          $(item).css({
            'left': offset + 'px'
          });
        }
        feedbackCarouselIndex += 1;

        $(".feedback-customer__prev").removeClass("disabled");
        if (feedbackCarouselIndex === initialLengthFeedbackCarousel) {
          $(this).addClass("disabled");
        }
      } else {
        for (var i = 0; i < items.length; i++) {
          var item = items.get(i);
          offset = Number($(item).css("left").replace("px", "")) + carouselWidth;
          $(item).css({
            'left': offset + 'px'
          });
        }
        feedbackCarouselIndex -= 1;

        $(".feedback-customer__next").removeClass("disabled");
        if (feedbackCarouselIndex === 1) {
          $(this).addClass("disabled");
        }
      }
    });
    // MOBILE SERVICE SECTION IN HOME PAGE
    setServiceHeight();
    $(window).on("resize", function () {
      setServiceHeight();
    });

    function setServiceHeight() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth < 768) {
        var serviceWrapper = $(".home .service .service__wrapper");
        $(".home .service").css("height", serviceWrapper.outerHeight());
      } else {
        $(".home .service").css("height", 600);
      }
    }

    // CLOSE RECRUIT POPUP
    $(".recruit-modal .modal-close").click(function () {
      var id = $(this).attr("data-id")
      $("#recruit-modal-"+id).removeClass("is-active");
      resetReadMoreProjectPopup(locale);
    });

    $(".modal-background").click(function(e) {
      var id = $(".recruit-modal.is-active .modal-close").attr("data-id")
      var isOutsideCard = $.contains($("#recruit-modal-"+id).get(0), e.target) && !$.contains($("#recruit-modal-"+id+" .modal-card").get(0), e.target);
      if (isOutsideCard && $("#recruit-modal-"+id).hasClass("is-active")) {
        $("#recruit-modal-"+id).removeClass("is-active");
        resetReadMoreProjectPopup(locale);
      }
    });

    // OPEN RECRUIT POPUP
    $(".recruit-button").click(function () {
      var id = $(this).attr("data-id")
      $("#recruit-modal-"+id).addClass("is-active");
    });

    // FILE UPLOAD DISPLAY
    $('#attachment').change(function() {
      var i = $(this).prev('label').clone();
      text = "";
      for (var i = 0; i < (this).files.length; ++i) {
        text += (this).files.item(i).name + ", ";
      }
      $(this).prev('label').text(text);
    });

    // MOBILE ONLY
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        isMobile = true;
    }
    console.log(navigator.userAgent)
    if (isMobile) {
      $(".modal-card-body").scroll(function() {
        if($(this).scrollTop() > 300){
          $(".modal-close").hide();
        } else {
          $(".modal-close").show();
      }
      });
    }
    // END OF MOBILE

    $(document).on("click", "#submit", function (e) {
      return checkform()
    });

    $(document).on("click", "#submitRecruit", function (e) {
      return checkformRecruit()
    });

    $("#form-recruit").submit(function (e) {
      if (checkform())
        return
      else e.preventDefault()
    })
  });

  function checkform() {
    var name = $("#name").val()
    var email = $("#email").val()
    var message = $("#message").val();
    var validationFailed = true
    if (name.length === 0) {
      $("span#name_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#name_field").css({ "display": "none" })
    }
    if (email.length === 0) {
      $("span#email_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#email_field").css({ "display": "none" })
    }
    if (message.length === 0) {
      $("span#message_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#message_field").css({ "display": "none" })
    }
    if (name.length > 0 && email.length > 0 && message.length > 0) {
      validationFailed = true
      $("span#email_field").css({ "display": "none" })
      $("span#name_field").css({ "display": "none" })
      $("span#message_field").css({ "display": "none" })
    }
    return validationFailed
  }

  function checkformRecruit() {
    var fullname = $("#fullname").val()
    var email = $("#email").val()
    var phone = $("#phone").val();
    try {
      var filesLength = $('#attachment')[0].files.length
    }
    catch(err) {
      console.log(err)
    }
    var validationFailed = true
    if (fullname.length === 0) {
      $("span#fullname_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#fullname_field").css({ "display": "none" })
    }
    if (email.length === 0) {
      $("span#email_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#email_field").css({ "display": "none" })
    }
    if (phone.length === 0) {
      $("span#phone_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#phone_field").css({ "display": "none" })
    }
    if (filesLength > 2) {
      $("span#attach_field").css({ "display": "block" })
      validationFailed = false
    } else {
      $("span#attach_field").css({ "display": "none" })
    }
    if (fullname.length > 0 && email.length > 0 && phone.length > 0 && filesLength < 3) {
      validationFailed = true
      $("span#email_field").css({ "display": "none" })
      $("span#fullname_field").css({ "display": "none" })
      $("span#phone_field").css({ "display": "none" })
      $("span#attach_field").css({ "display": "none" })
    }
    return validationFailed
  }

  function resetReadMoreProjectPopup(locale) {
    var readMore = $(".project-modal__read-more");
    var content = $(".project-modal__content");

    content.css({
      height: 100,
      overflow: "hidden",
    });
    var text = locale == 'en' ? 'Read more' : 'Đọc thêm'
    readMore.text(text);
  }
});